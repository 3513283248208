<template>
  <el-main>
    <div class="draw">
      <el-button type="primary" size="small" :disabled="!is_draw || type" @click="onDraw">随机抽签</el-button>
      <el-button type="text" size="small" @click="is_draw = !1">确定</el-button>
    </div>
    <div class="knockout">
      <div class="title">
        <span>淘汰赛</span>
        <span>（晋级3/4队进入四强）</span>
      </div>
      <div class="ranks-list">
        <div class="schedule" v-for="(item, index) in knockoutRanks" :key="index">
          <div class="num">{{ index + 1 != knockoutRanks.length ? '第' + (index + 1) + '轮' : '' }}</div>
          <div class="ranks" :style="{ height: ranks.ranks.length * 40 + 'px' }" v-for="(ranks, y) in item" :key="y">
            <el-select size="small" :disabled="type" v-if="index" v-model="ranks.value" @change="onSelectRanks(ranks.value, index)">
              <el-option v-for="(name, k) in index > 1 ? ranks.ranks2 : ranks.ranks" :key="k" :label="name.team_name" :value="name.id"></el-option>
            </el-select>
            <div v-else>
              <p v-for="(name, k) in ranks.ranks" :key="k">{{ name.team_name }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="confirm">
        <el-button type="primary" :disabled="type" size="small" @click="confirmKnockout">确认</el-button>
      </div>
    </div>
    <div class="repechage" v-if="RepechageRanks">
      <div class="title">
        <span>复活赛</span>
        <span>（晋级1队进入四强）</span>
      </div>
      <div class="repechage-ranks">
        <div class="title">
          <div>第1轮</div>
          <div>第2轮</div>
        </div>
        <div class="ranks">
          <div class="first">
            <div class="item" v-for="(item, index) in RepechageRanks" :key="index">
              <el-select size="small" :disabled="type > 1 ? true : false" v-model="item.value" @change="onSelectRepechage(index, item.value)">
                <el-option v-for="(ranks, y) in item.ranks" :key="y" :label="ranks.team_name" :value="ranks.id"></el-option>
              </el-select>
            </div>
          </div>
          <div class="last">
            <el-select size="small" :disabled="type > 1 ? true : false" v-model="RepechageId">
              <el-option v-for="(ranks, y) in RepechageRanks2" :key="y" :label="ranks.team_name" :value="ranks.id"></el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="confirm">
        <el-button type="primary" size="small" :disabled="type > 1 ? true : false" @click="confirmRepechange">确认</el-button>
      </div>
    </div>
    <div class="champion" v-if="championRanks">
      <div class="title">
        <span>四强争夺冠亚军</span>
      </div>
      <div class="champion-ranks">
        <div class="semifinals">
          <div class="title">半决赛</div>
          <div class="item" v-for="(item, index) in championRanks" :key="index">
            <div>
              <p v-for="ranks in item.ranks" :key="ranks.id">{{ ranks.team_name }}</p>
            </div>
          </div>
        </div>
        <div class="finals">
          <div class="title">决赛</div>
          <div class="item" v-for="(item, index) in championRanks" :key="index">
            <el-select size="small" :disabled="type > 2 ? true : false" v-model="item.value" @change="onSelectChampions(index, item.value)">
              <el-option v-for="ranks in item.ranks" :key="ranks.id" :label="ranks.team_name" :value="ranks.id"></el-option>
            </el-select>
          </div>
        </div>
        <div class="champions">
          <div class="title">冠军</div>
          <div class="item">
            <el-select size="small" :disabled="type > 2 ? true : false" v-model="championId">
              <el-option v-for="ranks in championRanks2" :key="ranks.id" :label="ranks.team_name" :value="ranks.id"></el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="confirm">
        <el-button type="primary" size="small" :disabled="type > 2 ? true : false" @click="confirmChampions">确认</el-button>
      </div>
    </div>
    <div class="secondPlace" v-if="secondPlaceRanks">
      <div class="title">
        <span>季军争夺</span>
      </div>
      <div class="secondPlace-ranks">
        <div class="left">
          <div class="title">第1轮</div>
          <div class="item">
            <p v-for="(item, index) in secondPlaceRanks" :key="index">{{ item.team_name }}</p>
          </div>
        </div>
        <div class="right">
          <div class="title">季军</div>
          <div class="item">
            <el-select size="small" :disabled="type > 3 ? true : false" v-model="secondPlaceId">
              <el-option v-for="ranks in secondPlaceRanks" :key="ranks.id" :label="ranks.team_name" :value="ranks.id"></el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="confirm">
        <el-button type="primary" size="small" :disabled="type > 3 ? true : false" @click="confirmSecondPlace">确认</el-button>
      </div>
    </div>
  </el-main>
</template>

<script>
import _ from 'lodash';
export default {
  data() {
    return {
      id: '',
      type: 0,
      is_draw: !0, //是否可以抽签
      ranksList: [],
      knockoutTimes: 1, //淘汰赛次数
      knockoutRanks: null, //淘汰赛队伍
      RepechageRanks: null, //复活赛队伍
      RepechageRanks2: [],
      championRanks: null,
      championRanks2: [],
      secondPlaceRanks: null,
      RepechageId: '',
      championId: '',
      secondPlaceId: '',
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.getRanks();
  },
  methods: {
    onDraw() {
      if (this.is_draw) this.handleKnockoutDraw();
    },
    drawLotsSubmit(type) {
      this.$axios
        .post(this.$api.sports.drawLotsSubmit, {
          events_id: this.id,
          type: type,
          data: {
            type: type,
            is_draw: this.is_draw, //是否可以抽签
            ranksList: this.ranksList,
            knockoutTimes: this.knockoutTimes, //淘汰赛次数
            knockoutRanks: this.knockoutRanks, //淘汰赛队伍
            RepechageRanks: this.RepechageRanks, //复活赛队伍
            RepechageRanks2: this.RepechageRanks2,
            championRanks: this.championRanks,
            championRanks2: this.championRanks2,
            secondPlaceRanks: this.secondPlaceRanks,
            RepechageId: this.RepechageId,
            championId: this.championId,
            secondPlaceId: this.secondPlaceId,
          },
        })
        .then(res => {
          if (res.code == 0) {
            this.type = type;
            this.$message.success('已确认');
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    //获取队伍数量
    getRanks() {
      this.$axios
        .post(this.$api.sports.drawLotsInfo, {
          events_id: this.id,
        })
        .then(res => {
          if (res.code == 0) {
            this.ranksList = res.result.team;
            if (res.result.draw_lots) {
              let data = JSON.parse(res.result.draw_lots.data);
              for (let i in data) {
                this[i] = data[i];
              }
              this.type = Number(this.type);
              this.knockoutTimes = Number(this.knockoutTimes);
              this.is_draw = Boolean(this.is_draw);
            } else {
              this.handleKnockoutTimes(this.ranksList.length);
              this.handleKnockoutDraw(1);
            }
          }
        });
    },
    //计算淘汰赛有几轮
    handleKnockoutTimes(num) {
      if (num >= 5) {
        this.knockoutTimes++;
        this.handleKnockoutTimes(Math.ceil(num / 2));
      }
    },
    //确认淘汰赛
    confirmKnockout() {
      let ranksList = this.knockoutRanks[this.knockoutTimes - 1];
      for (let i in ranksList) {
        if (!ranksList[i].value) return this.$message.warning('请选择四强队伍');
      }
      if (ranksList.length < 4) {
        let ranks = _.cloneDeep(this.ranksList);
        for (let i in ranksList) {
          ranks = ranks.filter(item => item.id != ranksList[i].value);
        }
        let len = Math.ceil(ranks.length / 2);
        let arr = [];
        for (let i = 0; i < ranks.length; i += len) {
          arr.push({
            value: '',
            ranks: ranks.slice(i, i + len),
          });
        }
        this.RepechageRanks = arr;
      } else {
        let topFour = []; //四强队伍
        this.championRanks = [];
        for (let i in ranksList) {
          let ranks = this.ranksList.find(item => item.id == ranksList[i].value);
          if (ranks) topFour.push(ranks);
        }
        for (let i = 0; i < topFour.length; i += 2) {
          this.championRanks.push({
            ranks: topFour.slice(i, i + 2),
            value: '',
          });
        }
      }
      this.drawLotsSubmit(1);
    },
    //选择复活赛
    onSelectRepechage(index, id) {
      let is_ranks = this.ranksList.find(item => item.id == id);
      if (this.RepechageRanks2.length == 2) {
        let value = [];
        let delIndex = 0;
        this.RepechageRanks.map(item => {
          if (item.value) {
            value.push(item.value);
          }
        });
        for (let y in value) {
          this.RepechageRanks2.map((item, a) => {
            if (item.id == value[y]) delIndex = a;
          });
        }
        delIndex ? this.RepechageRanks2.splice(0, 1) : this.RepechageRanks2.splice(1, 1);
        this.RepechageRanks2.push(is_ranks);
      } else {
        this.RepechageRanks2.push(is_ranks);
      }
      this.RepechageId = '';
    },
    //确认复活赛
    confirmRepechange() {
      if (!this.RepechageId) return this.$message.warning('请选择复活赛队伍');
      let knockoutRanks = this.knockoutRanks[this.knockoutTimes - 1];
      let ranksList = this.ranksList;
      let topFour = []; //四强队伍
      this.championRanks = [];
      for (let i in knockoutRanks) {
        let ranks = ranksList.find(item => item.id == knockoutRanks[i].value);
        if (ranks) topFour.push(ranks);
      }
      topFour.push(ranksList.find(item => item.id == this.RepechageId));
      for (let i = 0; i < topFour.length; i += 2) {
        this.championRanks.push({
          ranks: topFour.slice(i, i + 2),
          value: '',
        });
      }
      this.drawLotsSubmit(2);
    },
    //选择冠军
    onSelectChampions(index, id) {
      let is_ranks = this.ranksList.find(item => item.id == id);
      if (this.championRanks2.length == 2) {
        let value = [];
        let delIndex = 0;
        this.championRanks.map(item => {
          if (item.value) {
            value.push(item.value);
          }
        });
        for (let y in value) {
          this.championRanks2.map((item, a) => {
            if (item.id == value[y]) delIndex = a;
          });
        }
        delIndex ? this.championRanks2.splice(0, 1) : this.championRanks2.splice(1, 1);
        this.championRanks2.push(is_ranks);
      } else {
        this.championRanks2.push(is_ranks);
      }
      this.championId = '';
    },
    //确认冠军
    confirmChampions() {
      if (!this.championId) return this.$message.warning('请选择冠军队伍');
      this.secondPlaceRanks = [];
      let championRanks = _.cloneDeep(this.championRanks);
      for (let i in championRanks) {
        championRanks[i].ranks = championRanks[i].ranks.filter(item => item.id != championRanks[i].value);
        this.secondPlaceRanks.push(...championRanks[i].ranks);
      }
      this.drawLotsSubmit(3);
    },
    //确认季军
    confirmSecondPlace() {
      if (!this.secondPlaceId) return this.$message.success('请选择季军队伍');
      this.drawLotsSubmit(4);
    },
    //淘汰赛选择队伍
    onSelectRanks(id, index) {
      if (this.knockoutTimes > 2) {
        let currentRanks = this.knockoutRanks[index]; //选择当前第几轮队伍的时候拿到当前轮队伍数据
        let behindRanks = this.knockoutRanks[index + 1]; //选择当前第几轮队伍的时候拿到下一轮队伍数据
        for (let i in behindRanks) {
          let is_ranks = behindRanks[i].ranks.find(item => item.id == id);
          if (is_ranks) {
            if (!behindRanks[i].ranks2.find(item => item.id == id)) {
              //判断有没有轮空队伍
              if (behindRanks[i].ranks.length == currentRanks[0].ranks.length) {
                if (behindRanks[i].ranks2.length) {
                  behindRanks[i].ranks2.splice(0, 1);
                  behindRanks[i].value = '';
                }
                behindRanks[i].ranks2.push(is_ranks);
                return;
              }
              if (behindRanks[i].ranks2.length == 2) {
                let value = [];
                let delIndex = 0;
                currentRanks.map(item => {
                  if (item.value) {
                    value.push(item.value);
                  }
                });
                for (let y in value) {
                  behindRanks[i].ranks2.map((item, a) => {
                    if (item.id == value[y]) delIndex = a;
                  });
                }
                delIndex ? behindRanks[i].ranks2.splice(0, 1) : behindRanks[i].ranks2.splice(1, 1);
                behindRanks[i].ranks2.push(is_ranks);
              } else {
                behindRanks[i].ranks2.push(is_ranks);
              }
              behindRanks[i].value = '';
            }
          }
        }
      }
    },
    //处理淘汰赛抽签
    handleKnockoutDraw(isFirst) {
      let ranksList = this.ranksList;
      this.knockoutRanks = [[], []];
      if (!isFirst) {
        ranksList.sort(function () {
          return 0.5 - Math.random();
        });
      }
      if (this.knockoutTimes > 2) {
        for (let y = 0; y < this.knockoutTimes; y++) {
          if (y == 0) {
            for (let i = 0; i < ranksList.length; i += 2) {
              this.knockoutRanks[0].push({
                ranks: ranksList.slice(i, i + 2),
                value: '',
              });
            }
          } else if (y == 1) {
            for (let i = 0; i < ranksList.length; i += 2) {
              this.knockoutRanks[1].push({
                ranks: ranksList.slice(i, i + 2),
                value: '',
              });
            }
          } else {
            let arr = [];
            let len = this.knockoutRanks[this.knockoutRanks.length - 1][0].ranks.length * 2;
            for (let i = 0; i < ranksList.length; i += len) {
              arr.push({
                ranks: ranksList.slice(i, i + len),
                ranks2: [],
                value: '',
              });
            }
            this.knockoutRanks.push(arr);
          }
        }
      } else {
        for (let i = 0; i < ranksList.length; i += 2) {
          this.knockoutRanks[0].push({
            ranks: ranksList.slice(i, i + 2),
            value: '',
          });
          this.knockoutRanks[1].push({
            ranks: ranksList.slice(i, i + 2),
            value: '',
          });
        }
        let lastRanks = this.knockoutRanks[1]; //最后一个队伍
        let lastRanksNum = lastRanks[lastRanks.length - 1]; //最后一个队伍的数量
        if (lastRanksNum.ranks.length % 2) {
          lastRanksNum.value = lastRanksNum.ranks[0].id;
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  font-size: 14px;
  .draw {
    margin-bottom: 30px;
  }
  .knockout,
  .repechage,
  .champion,
  .secondPlace {
    margin-bottom: 20px;
    .title {
      padding-bottom: 10px;
      border-bottom: 1px solid #ebeef5;
      span:last-child {
        font-size: 12px;
      }
    }
    .secondPlace-ranks {
      display: flex;
      .left,
      .right {
        .title {
          height: 40px;
          width: 170px;
          line-height: 40px;
          text-align: center;
          background: #f8f9fa;
        }
        .item {
          width: 170px;
          height: 70px;
          padding: 0 10px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          border-bottom: 1px solid #f8f9fa;
          border-right: 1px solid #f8f9fa;
          p:nth-child(1) {
            margin-bottom: 10px;
          }
        }
      }
    }
    .champion-ranks {
      margin-top: 20px;
      display: flex;
      .title {
        width: 170px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background: #f8f9fa;
      }
      .item {
        padding: 0 10px;
        height: 60px;
        width: 170px;
        border-bottom: 1px solid #f8f9fa;
        border-right: 1px solid #f8f9fa;
        display: flex;
        justify-content: center;
        flex-direction: column;
        p:nth-child(1) {
          margin-bottom: 10px;
        }
      }
      .champions .item {
        height: 120px;
      }
    }
    .repechage-ranks {
      margin-top: 20px;
      border-left: 1px solid #f8f9fa;
      .title {
        display: flex;
        padding: 0;
        border: 0;
        div {
          height: 40px;
          line-height: 40px;
          text-align: center;
          width: 170px;
          background: #f8f9fa;
        }
      }
      .ranks {
        display: flex;
        .first {
          width: 170px;
          height: 120px;
          .item {
            height: 60px;
            border-bottom: 1px solid #f8f9fa;
            border-right: 1px solid #f8f9fa;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 10px;
          }
        }
        .last {
          height: 120px;
          width: 170px;
          border-bottom: 1px solid #f8f9fa;
          border-right: 1px solid #f8f9fa;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .ranks-list {
      display: flex;
      border-left: 1px solid #f8f9fa;
      margin-top: 20px;
      .schedule {
        .num {
          background: #f8f9fa;
          height: 40px;
          line-height: 40px;
          text-align: center;
        }
        .ranks {
          width: 170px;
          display: flex;
          align-items: center;
          padding: 0 10px;
          border-bottom: 1px solid #f8f9fa;
          border-right: 1px solid #f8f9fa;
          div p {
            margin-bottom: 10px;
            &:last-child {
              margin: 0;
            }
          }
        }
      }
    }
    .confirm {
      margin-top: 20px;
    }
  }
}
</style>